import { Box, useTheme } from '@mui/material';
import React, { useEffect } from 'react';

const AdsComponent = () => { 

    useEffect(() => {
        try {
            if (!(window as any).adsbygoogle?.loaded) {
                (window as any).adsbygoogle = (window as any).adsbygoogle || [];
                (window as any).adsbygoogle.push({});
            }
        } catch (e) {
            console.log('Error occurred while loading ads: ', e);
        }
    }, []);

    const theme = useTheme();

    return (
        <Box 
            sx={{ 
                backgroundColor: theme.palette.primary.main, 
                height: '100%', // Ensure it takes the full height of the parent Box
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'center' 
            }}
        >
            <ins 
                className='adsbygoogle'
                style={{ display: 'block', height: '100%', width: '100%' }}
                data-ad-client='ca-pub-1741084523346356'
                data-ad-slot='9413335135'
                data-ad-format='auto'
                data-adtest={process.env.NODE_ENV === 'dev' ? 'on' : 'off'}
                data-full-width-responsive='true'>
            </ins>
        </Box>
    );
};

export default AdsComponent;