import { Items } from 'models';
import { useRef } from 'react';

export default function useLongPress(callback: (item: Items) => void, ms = 300) {
    const timerId = useRef<NodeJS.Timeout | null>(null); // Persistent timer reference

    const startLongPress = (row: Items) => {
        timerId.current = setTimeout(() => {
            console.log('callback');
            callback(row); // Trigger the callback
            clearTimeout(timerId.current!); // Cleanup after the callback
            timerId.current = null;
        }, ms);
    };

    const resetLongPress = () => {
        if (timerId.current) {
            clearTimeout(timerId.current); // Clear the timer
            timerId.current = null;
        }
    };

    return {
        handlers: {
            onMouseDown: (row: Items) => startLongPress(row), // Start on mouse down
            onTouchStart: (row: Items) => startLongPress(row), // Start on touch start
            onMouseUp: resetLongPress, // Reset on mouse up
            onMouseLeave: resetLongPress, // Reset on mouse leave
            onTouchEnd: resetLongPress, // Reset on touch end
        },
        resetLongPress, // Expose reset function (for external use like scrolling)
    };
}