/**
     * Formats a number to a currency string
     * @param value 
     * @returns the formatted currency string
     */
const getCurrencyStringForLines = (value: number) => {
    return new Intl.NumberFormat('en-ZA', { style: 'currency', currency: 'ZAR', }).format(value).replace(',', ' ');
}

/**
     * Formats a number to display for the totals
     * @param value 
     * @returns the formatted currency string
     */
const getCurrencyStringForTotal = (value: number) => {
    return new Intl.NumberFormat('en-ZA', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2, }).format(value).replace(',', ' ');
}

export {
    getCurrencyStringForLines,
    getCurrencyStringForTotal
}