import { Items, PoolResult } from "models";

/**
     * Calculates the price for 1 item on a line
     * @param item the line item to calculate the single item price for
     * @returns the price of a single item on the line
     */
const getSingleItemPrice = (item: Items) => {
    return Number(item.total) / Number(item.qty);
}

/**
     * Calculates the difference between the quantity of an item and the total quantity picked by users.
     * @param item - The item for which to calculate the quantity difference.
     * @returns The difference between the item's quantity and the total quantity picked by users.
     */
const getItemQtyDifference = (item: Items) => {
    var totalPicked = item.userSelections.map(x => x.qty).reduce((a, b) => { if (a !== undefined && b) { return a + b } else return 0 }, 0);
    return Number(item.qty) - totalPicked
}

/**
 * Gets the userItemSelection qty for a specific item for the logged in user
 * @param item the line item to get the user's qty for
 * @returns the total picked qty
 */
const getUserItemTotalSelected = (userId: string, item: Items) => {
    var totalPicked = item.userSelections
        .filter(selection => selection.userId === userId)
        .map(x => x.qty)
        .reduce((a, b) => { if (a !== undefined && b) { return a + b } else return 0 }, 0);
    return totalPicked
}

/**
     * Gets the total picked by all users for a specific line item
     * @param item the line item to calculate the total picked for
     * @returns the amount picked by all users for the line
     */
const getAllItemTotalSelected = (item: Items) => {
    var totalPicked = item.userSelections
        .map(x => x.qty)
        .reduce((a, b) => { if (a !== undefined && b) { return a + b } else return 0 }, 0);
    return totalPicked
}

/**
     * Get what should be displayed next to the tip value based on user selection
     * @returns a percentage string or an empty string
     */
const getFormattedTipPercentageSelectedByUser = (userId: string, data: PoolResult | undefined) => {
    const tipResult = data?.ocrResult?.tip?.find(x => x.userId === userId);
    if (tipResult?.percentage) {
        return `${tipResult.percentage}%`;
    }
    return '';
}

/**
     * Gets the price for the bill plus the tip based on the selections made by the logged in user
     * @returns the bill total and tip for the logged in user
     */
const getTotalDueSelectedByUser = (userId: string, poolResult?: PoolResult | undefined) => {
    if(!poolResult){
        return 0;
    }
    const userTotal = poolResult?.ocrResult?.lineItems
        .filter(lineItem => {
            return lineItem.userSelections
                .filter(selection => selection.userId === userId)
        })
        .map(x => Number(x.total) / Number(x.qty) * getUserItemTotalSelected(userId, x))
        .reduce((a, b) => a + b, 0);

    const userTip = poolResult?.ocrResult?.tip ?
        poolResult?.ocrResult?.tip.find(tipItem => {
            return tipItem.userId === userId
        })?.tip : 0;

    const totalDue = (userTotal ?? 0) + (userTip ?? 0);

    return totalDue;
}

export {
    getSingleItemPrice,
    getItemQtyDifference,
    getUserItemTotalSelected,
    getAllItemTotalSelected,
    getFormattedTipPercentageSelectedByUser,
    getTotalDueSelectedByUser
}