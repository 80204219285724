import * as React from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Modal, Typography } from "@mui/material";

export default function ConfirmDialog({
    content,
    open,
    output,
    onClose
}: {
    content: string,
    open: boolean,
    output: (confirm: boolean) => void,
    onClose: () => void
}) {

    const handleCancel = () => {
        onClose();
    };

    const handleConfirm = () => {
        output(true);
        onClose();
    }

    return (
        <Dialog
            open={open}
            onClose={handleCancel}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"Are you done with this bill?"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {content ? 
                        <Box>
                            <Typography variant="subtitle1" textAlign="center">Total Due</Typography> 
                            <Typography variant="h4" textAlign="center">{content}</Typography> 
                        </Box>
                        : "Tank you for using SplitMyBill"
                    }
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel}>No</Button>
                <Button onClick={handleConfirm} autoFocus>
                    Yes
                </Button>
            </DialogActions>
        </Dialog>
    )
}