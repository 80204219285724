import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, InputAdornment, TextField } from "@mui/material";
import { Items } from "models";
import React from "react";
import { useEffect, useState } from "react"

export default function EditLineItemDialog({
    open,
    input,
    output,
    onClose
}: {
    open: boolean,
    input: Items | null,
    output: (item: Items) => void,
    onClose: () => void
}) {
    const [description, setDescription] = useState<string>('');
    const [price, setPrice] = useState<number | undefined>(0);
    const [qty, setQty] = useState<number | undefined>(0);

    useEffect(() => {
        if (input) {
            setDescription(input.description ?? '');
            if (input.qty !== null && input.total !== null) {
                var newPrice = (Number(input.total) / Number(input.qty));
                if (newPrice) setPrice(newPrice);
            }
            if (input.qty !== null) {
                setQty(Number(input.qty));
            }
        }
    }, [input]);

    const handleDescriptionChange = (newValue: string) => {
        setDescription(newValue);
    }

    const handlePriceChange = (newValue: string) => {
        let isnum = /^[0-9]+(\.?[0-9]{0,2})?$/.test(newValue);
        let isEmpty = /^$/.test(newValue);
        if (isnum) {
            setPrice(Number(newValue));
        }
        if (isEmpty) {
            setPrice(undefined);
        }
    }

    const handleQuantityChange = (newValue: string) => {
        let isnum = /^[0-9]+(\.?[0-9]{0,2})?$/.test(newValue);
        let isEmpty = /^$/.test(newValue);
        if (isnum) {
            setQty(Number(newValue));
        }
        if (isEmpty) {
            setQty(undefined);
        }
    }

    const handleCancel = () => {
        onClose();
    };

    const handleConfirm = () => {
        if (price && qty) {
            output({
                ...input,
                description: description,
                price: price.toString(),
                qty: qty.toString(),
                total: (price * qty).toString(),
            } as Items);
            onClose();
        }
    }

    return (
        <React.Fragment>
            <Dialog
                open={open}
                onClose={handleCancel}
            >
                <DialogTitle>Edit Line Item</DialogTitle>
                <DialogContent>
                    <DialogContentText
                        margin={2}>
                        You can make changes to the description, price, and quantity for a line item.
                    </DialogContentText>
                    {input &&
                        <Box
                            noValidate
                            component="form"
                            sx={{
                                p: 1.5,
                                display: 'flex',
                                flexDirection: 'column',
                                m: 'auto',
                            }}
                            gap={2}
                        >
                            <TextField
                                error={!description}
                                label="Description"
                                id="outlined-start-adornment"
                                value={description}
                                type='text'
                                onChange={(event) => handleDescriptionChange(event.target.value)}
                                slotProps={{
                                    inputLabel: {
                                        shrink: true, // Keeps the label permanently in the focused position
                                    },
                                }}
                            />

                            <TextField
                                error={price === undefined || price === 0}
                                label="Price"
                                id="outlined-start-adornment"
                                value={price}
                                type='number'
                                slotProps={{
                                    input: {
                                        startAdornment: <InputAdornment position="start">R</InputAdornment>,
                                    },
                                    inputLabel: {
                                        shrink: true, // Keeps the label permanently in the focused position
                                    },
                                }}
                                onChange={(event) => handlePriceChange(event.target.value)}
                            />

                            <TextField
                                error={qty === undefined || qty === 0}
                                label="Qty"
                                id="outlined-start-adornment"
                                value={qty}
                                type='number'
                                onChange={(event) => handleQuantityChange(event.target.value)}
                                slotProps={{
                                    inputLabel: {
                                        shrink: true, // Keeps the label permanently in the focused position
                                    },
                                }}
                            />
                        </Box>
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancel}>Cancel</Button>
                    <Button onClick={handleConfirm}>OK</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}