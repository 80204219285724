import { createTheme, ThemeOptions } from '@mui/material/styles';

const lightTheme: ThemeOptions = {
    palette: {
        mode: 'light',
        primary: {
            main: '#6C538B',
            light: '#EEDBFF',
            dark: '#260D43',
            contrastText: '#FFFFFF'
        },
        secondary: {
            main: '#655A6F',
            light: '#ECDDF7',
            dark: '#20182A',
            contrastText: '#FFFFFF'
        },
        error: {
            main: '#BA1A1A',
            light: '#FFDAD6',
            dark: '#410002',
            contrastText: '#FFFFFF'
        },
        background: {
            default: '#FFF7FF',
            paper: '#FFF7FF'
        },
        text: {
            primary: '#1D1A20',
            secondary: '#4A454E'
        },
        divider: '#7B757F',
    },
    typography: {
        fontFamily: 'Montserrat, sans-serif',
    },
};

const darkTheme: ThemeOptions = {
    palette: {
        mode: 'dark',
        primary: {
            main: '#D8BAFA',
            light: '#EEDBFF',
            dark: '#3C245A',
            contrastText: '#260D43'
        },
        secondary: {
            main: '#CFC1DA',
            light: '#ECDDF7',
            dark: '#362D40',
            contrastText: '#20182A'
        },
        error: {
            main: '#FFB4AB',
            light: '#FFDAD6',
            dark: '#93000A',
            contrastText: '#690005'
        },
        background: {
            default: '#151218',
            paper: '#151218'
        },
        text: {
            primary: '#E8E0E8',
            secondary: '#CCC4CF'
        },
        divider: '#958E99',
    },
    typography: {
        fontFamily: 'Montserrat, sans-serif',
    },
};

const themeOverrides = {
    components: {
        MuiContainer: {
            styleOverrides: {
                root: {
                    '@media (min-width: 1200px)': {
                        maxWidth: 'none', // Remove the default maxWidth
                    },
                },
            },
        },
    },
}

// Export both themes
const themes = {
    light: createTheme(lightTheme, themeOverrides),
    dark: createTheme(darkTheme, themeOverrides),
};

export default themes;
