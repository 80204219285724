import React, { useEffect, useState } from 'react';
import { SignIn, useAuth, useSession } from "@clerk/clerk-react";
import { Box, InputAdornment, TextField, Grid2 as Grid } from '@mui/material';
import { useAddUserToPoolMutation, useCreateUserMutation, useUpsertUserByClerkIdMutation } from '../api/queries';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { setUser } from '../api/user.slice';
import { useDispatch, useSelector } from 'react-redux';
import { AccountCircle } from '@mui/icons-material';
import types from "models/dist/index";
import CustomButton from '../components/Button';

export default function LoginPage() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector((state: any) => state.user);

    const { isSignedIn, userId } = useAuth();
    const { session } = useSession();
    const [tempName, setTempName] = useState<string>('');

    const [upsertClerkUser] = useUpsertUserByClerkIdMutation();
    const [createUser] = useCreateUserMutation();
    const [addUserToPool] = useAddUserToPoolMutation();

    const [searchParams] = useSearchParams();
    const poolId = searchParams.get("poolId");

    // Create a new user using the provided information
    const checkUserPool = async (userData: types.User) => {
        try {
            if (!poolId) {
                navigate(`/`);
            } else {
                const response = await addUserToPool({
                    userId: userData.id,
                    poolId: poolId ?? ''
                }).unwrap();

                if (response.id) {
                    navigate(`/receipt-processed?poolId=${poolId}`);
                } else {
                    throw new Error();
                }
            }
        } catch (error) {
            console.error("Error creating user:", error);
        }
    };

    // Create a new user and sign them in if required
    const onCreateButtonClick = async () => {
        const response = await createUser({
            name: tempName ?? '',
            tempUser: true
        }).unwrap();

        if (user) {
            dispatch(setUser({
                userId: response.id,
                userName: response.name,
                status: 'guestUser',
                error: null
            }));
        }

        await checkUserPool(response);
    }

    const checkNewUser = async () => {
        if (isSignedIn && userId) {
            const user = await upsertClerkUser({
                name: tempName ?? '',
                clerkId: userId ?? '',
                email: userId ? session?.user.emailAddresses[0].emailAddress : '',
                tempUser: true
            }).unwrap();

            if (user) {
                dispatch(setUser({
                    userId: user.id,
                    userName: user.name,
                    status: 'signedIntoClerk',
                    clerkUser: userId ? {
                        email: userId ?? '',
                        id: userId
                    } : undefined,
                    error: null
                }));
            }

            await checkUserPool(user);
        }
    }

    useEffect(() => {
        checkNewUser();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userId, isSignedIn])

    if (!user.userId && !isSignedIn) {
        return (
            <Box sx={{ py: 3 }}>
                <Grid container direction="column" justifyContent="center" alignItems="center" mt={3}>
                    <Grid container direction="column" justifyContent="center" alignItems="center">
                        <SignIn />
                    </Grid>
                    <TextField
                        id="input-with-icon-textfield"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <AccountCircle />
                                </InputAdornment>
                            ),
                        }}
                        label="Temporary Name"
                        variant="filled"
                        value={tempName}
                        onChange={(event) => {
                            setTempName(event?.target.value);
                        }}
                        sx={{
                            mt: 2,
                        }}
                    />
                    <CustomButton
                        onClick={onCreateButtonClick}
                        buttonText="Create Temporary User"
                        top="88%"
                        left="50%"
                        transform="translate(-50%, -50%) "
                        width='60vw'
                    />
                </Grid>
            </Box>
        )
    }
}